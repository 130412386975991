
//Import scss, css, js files, it can be either dist or src

import './vendor/bootstrap/dist/css/bootstrap.css'
import './vendor/components-font-awesome/css/font-awesome.min.css'
import './vendor/animate/animate.css'
import './vendor/bxslider-4/dist/jquery.bxslider.min.css'
import './vendor/owl.carousel/dist/assets/owl.carousel.min.css'
import './vendor/magnific-popup/dist/magnific-popup.css'
import './vendor/fotorama-4.6.4/fotorama.css'
import './vendor/jquery-ui-1.12.1.custom/jquery-ui.min.css'

import './vendor/jquery/jquery-2.2.4.min.js'
import './vendor/tether/tether-1.4.0.min.js'
import './vendor/bootstrap/dist/js/bootstrap.min.js'
import './vendor/viewport-checker/viewport-checker.js'
import './vendor/bxslider-4/dist/jquery.bxslider.min.js'
import './vendor/owl.carousel/dist/owl.carousel.js'
import './vendor/magnific-popup/dist/jquery.magnific-popup.min.js'
import './vendor/fotorama-4.6.4/fotorama.js'
import './vendor/jquery-ui-1.12.1.custom/jquery-ui.min.js'
